var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Update',
    key: 'update',
    loading: _vm.makingApiRequest,
    disabled: !_vm.formIsValid || !Object.keys(_vm.updatedFields).length
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[(_vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.resourceNotFound && !_vm.showActivities)?_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest || !_vm.initialized),expression:"makingApiRequest || !initialized"}],ref:"formElement",attrs:{"schemas":_vm.formFields,"formValues":_vm.currentlyEditing,"dynamicOptions":{
      companies: _vm.companyOptions,
      policyType: _vm.appliesToOptions
    },"element-loading-text":_vm.loadingText},on:{"validationChanged":function($event){_vm.validationData = $event},"formFieldChanged":_vm.formFieldChangedHandler}}):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}])}):_vm._e(),_c('ModalMessage',{attrs:{"name":"underWritingQuestionSingleEditModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.__getText('global', 'willSaveChangesTitle'),"description":_vm.__getText('global', 'willSaveChanges'),"showClose":true,"buttons":[
      {
        text: `Ok`,
        classList: [''],
        click: () => _vm.submit()
      },
      {
        text: `Cancel`,
        classList: [''],
        click: () => _vm.$modal.hide('endorsementSingleEditModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }