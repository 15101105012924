import {
  useFormFieldGroup,
  useFormSection
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "../../components/FormBuilder/types";
import { useTextField } from "../../components/FormBuilder/Helpers/fields/useTextField";
import { useSelectField } from "../../components/FormBuilder/Helpers/fields/useSelectField";
import {
  yesNoOptions,
  booleanOptions,
  companyLineOfBusiness
} from "@/helpers/selectOptions";
import { useField } from "../../components/FormBuilder/Helpers/fields/useField";
const validResponses = [
  ...yesNoOptions,
  {
    value: "any",
    label: "Any"
  }
];
export const underWritingQuestionsForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useField({
          key: "question",
          label: "Question",
          placeholder: "Question",
          required: true,
          type: "textarea"
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "validResponse",
          label: "Valid Response",
          options: validResponses,
          required: false
        }),
        useSelectField({
          key: "needsApprovalCode",
          label: "Needs Approval Code",
          options: booleanOptions,
          required: true
        }),
        useTextField({
          key: "errorMessage",
          label: "Error Message",
          required: false,
          placeholder: "Error Message",
          conditions: {
            or: [
              {
                operator: "not",
                field: "validResponse",
                value: "any"
              }
            ]
          }
        })
      ],
      { layout: "3-col" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "additionalModalCheck",
          label: "Display additional popup if user selects ",
          required: false,
          clearable: true,
          options: yesNoOptions,
          conditions: {
            or: [
              {
                operator: "exists",
                field: "validResponse",
                value: true
              }
            ]
          }
        }),
        useField({
          key: "additionalModalMessage",
          label: "Message for additional popup",
          placeholder: "",
          required: true,
          type: "textarea",
          conditions: {
            and: [
              {
                operator: "exists",
                field: "validResponse",
                value: true
              },
              {
                operator: "exists",
                field: "additionalModalCheck",
                value: true
              }
            ]
          }
        })
      ],
      { layout: "2-col" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "policyType",
          label: "Policy Type",
          required: true,
          options: companyLineOfBusiness,
          filterable: false
        }),
        useField({
          key: "companies",
          label: "Select Companies",
          type: "select",
          required: true,
          multiple: true
        })
      ],
      { layout: "2-col" }
    )
  ])
];
