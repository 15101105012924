
import { FormBlock } from "@/components/FormBuilder/types";
import { underWritingQuestionsForm } from "@/forms/admin/underWritingQuestion";
import { objectDifference } from "@/helpers";
import {
  underWritingQuestionMapActions,
  underWritingQuestionMapGetters,
  underWritingQuestionMapMutations,
  underWritingQuestionMapState
} from "@/store/modules/underWritingQuestion";
import Vue from "vue";
import * as types from "@/store/mutation-types";
import { cloneDeep } from "lodash";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import { companyLineOfBusiness } from "../../../helpers/selectOptions";
import { IUnderWritingQuestion } from "@/store/modules/underWritingQuestion/types";

interface IEditUnderWritingQuestion {
  errorMessage: string;
  successMessage: string;
  initialized: boolean;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  validationData: any;
  showActivities: boolean;
  activities: any;

  loadingText: string;
}
export default Vue.extend({
  name: "EditUnderWritingQuestion",
  mixins: [ActivitiesMixin],
  data(): IEditUnderWritingQuestion {
    return {
      errorMessage: "",
      successMessage: "",
      initialized: false,
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The Underwriting Question you are looking for cannot be found.",
      validationData: {
        formIsValid: false
      },
      showActivities: false,
      activities: [],
      loadingText: "Submitting new underWritingQuestion data. Please wait..."
    };
  },
  mounted() {
    this.getUnderWritingQuestion(this.underWritingQuestionId)
      .then((question: IUnderWritingQuestion) => {
        if (question && Object.keys(question).length) {
          this.SET_EDIT(question);
        } else {
          this.errorMessage = "Error retrieving Underwriting Question data";
          this.resourceNotFound = true;
        }
        this.initialized = true;
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.initialized = true;
        this.$bugSnagClient.notify(error);
      });
  },
  computed: {
    ...underWritingQuestionMapGetters(["getUnderWritingQuestionById"]),
    ...underWritingQuestionMapState(["editing", "makingApiRequest"]),
    underWritingQuestionId(): string {
      return this.$route.params.underWritingQuestionId;
    },
    underWritingQuestion(): any {
      return this.getUnderWritingQuestionById(this.underWritingQuestionId);
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: any) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    },
    appliesToOptions(): { label: string; value: string }[] {
      return companyLineOfBusiness.map((option: any) => ({
        label: option.label,
        value: option.value
      }));
    },
    updatedFields(): any {
      const edited = this.editing;
      const original = this.underWritingQuestion;
      return edited && original
        ? objectDifference(edited, original, ["errorMessage"])
        : {};
    },
    disablePrimaryToolbar(): boolean {
      return Object.keys(this.updatedFields).length === 0;
    },
    formFields(): FormBlock[] {
      return underWritingQuestionsForm;
    },
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    },
    queryOverride(): any {
      return {
        "data.underWritingQuestionId": this.editing?._id,
        activityType: "UnderWritingQuestionActivity"
      };
    },
    currentlyEditing(): any {
      return this.editing || {};
    }
  },
  methods: {
    ...underWritingQuestionMapActions([
      "updateUnderWritingQuestion",
      "getUnderWritingQuestion",
      "deleteUnderWritingQuestion"
    ]),
    ...underWritingQuestionMapMutations(["SET_EDIT"]),
    ...underWritingQuestionMapMutations({ editField: types.SET_EDIT_FIELD }),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "update":
          this.promptUpdateConfirmation();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async promptUpdateConfirmation(): Promise<void> {
      try {
        this.$modal.show("underWritingQuestionSingleEditModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    async cancelCallback(): Promise<any> {
      this.$router.push("/admin/underwriting-questions").catch(() => {});
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    async submit(): Promise<void> {
      if (this.makingApiRequest) return;
      try {
        if (this.formIsValid) {
          let fieldsUpdated = cloneDeep(this.updatedFields);
          await this.updateUnderWritingQuestion({
            id: this.underWritingQuestionId,
            update: fieldsUpdated
          });
          this.successMessage = "UnderWritingQuestion updated successfully";
          this.$router.push("/admin/underwriting-questions").catch(() => {});
        } else {
          this.errorMessage = "Please correct the errors in the form";
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async deleteCallback(): Promise<void> {
      try {
        this.$modal.show("underWritingQuestionSingleDeleteModal");
      } catch (error) {
        // do nothing
      }
    },
    async deleteOneUnderWritingQuestion(): Promise<void> {
      try {
        await this.deleteUnderWritingQuestion(this.underWritingQuestionId);
        this.successMessage = "UnderWritingQuestion deleted successfully";
        this.$router.push("/admin/underWritingQuestions").catch(() => {});
      } catch (error) {
        this.errorMessage = error.message;
      }
    }
  }
  // destroyed() {
  //   this.SET_EDIT(" " as any);
  // }
});
